/* eslint-disable no-undef */
import React from 'react';

import { connect } from 'react-redux';
import env from 'conf/env.json';

// reactstrap components
import {
  Row,
  Col,
  Button,
} from 'reactstrap';

import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from 'react-loader-spinner';

import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';
import GetLongStringModal from 'views/components/GetLongStringModal';
import Quill from 'views/components/Quill';

import ForumService from 'services/ForumService';

import Papel from 'assets/csv/papeis.json';

class Forum extends React.Component {
  constructor(props) {
    super(props);

    this.forumService = new ForumService();

    this.state = {
      showAlert: false,

      showLoaderModal: false,
      showConfirmModal: false,

      mensagens: [],
      total: 0,
      tamanhoPagina: 5,

      mensagem: '',

      erro: false,
    };
  }

  componentWillUnmount() {
    this.umounted = true;
    this.webSocket.close();
  }

  async componentDidMount() {
    try {
      const uri = `${env.BACKEND_URL_WEBSOCKET}/websocket/forum/${this.props.match.params.idTurma}-${this.props.match.params.idMateria}`;
      this.webSocket = new WebSocket(uri);
      this.webSocket.onopen = () => {
        this.carregarMensagens();
      };
      this.webSocket.onmessage = (event) => {
        const json = JSON.parse(event.data);
        if (json.tipo === 0) {
          this.setState({
            mensagens: [json.mensagem].concat(this.state.mensagens),
            total: this.state.total + 1,
          });
        } else {
          this.setState({
            mensagens: this.state.mensagens.filter((msg) => msg.id
              !== json.mensagem.id),
            total: this.state.total - 1,
          });
        }
      };
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarMensagens() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Carregando mensagens cadastradas...',
      showAlert: false,
    });

    try {
      const data = await this.forumService
        .carregarMensagens(this.props.match.params.idTurma,
          this.props.match.params.idMateria,
          1, this.state.mensagens.length + 5, '');

      this.setState({
        mensagens: data.mensagens,
        total: data.total,
        showLoaderModal: false,
      });
    } catch (e) {
      this.setState({ showLoaderModal: false });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar mensagens cadastradas',
      });
    }
  }

  async cadastrarMensagem(mensagem) {
    this.setState({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Cadastrando mensagem...',
    });

    try {
      const mensagemForum = {
        mensagem,
      };

      await this.forumService.cadastrarMensagem(
        this.props.match.params.idTurma,
        this.props.match.params.idMateria, mensagemForum,
      );

      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Mensagem cadastrada com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({ showLoaderModal: false });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar mensagem',
      });
    }
  }

  confirmarRemocaoMensagem(mensagem) {
    this.setState({ mensagemSelecionada: mensagem, showConfirmModal: true });
  }

  async removerMensagem() {
    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Excluindo mensagem...',
    });

    try {
      await this.forumService
        .removerMensagem(this.state.mensagemSelecionada.id);

      this.setState({
        showLoaderModal: false,
        mensagemSelecionada: undefined,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Mensagem removida com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        mensagemSelecionada: undefined,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover mensagem',
      });
    }
  }

  render() {
    return (
      <>
        {
          (this.props.role === Papel.ALUNO.value
            || this.props.role === Papel.PROFESSOR.value) && <Row>
            <Col md="12" className="text-right">
              <Button color="primary"
                onClick={() => this.setState({ showGetStringModal: true })}>
                Cadastrar Mensagem
              </Button>
            </Col>
          </Row>
        }
        <Row>
          <Col>
            <br />
            {
              this.state.mensagens.length > 0 ? <InfiniteScroll
                dataLength={this.state.mensagens.length}
                next={() => this.carregarMensagens()}
                scrollableTarget={'main-panel'}
                hasMore={this.state.mensagens.length < this.state.total}
                loader={<div align="center">
                  <Loader
                    type="Oval"
                    color="#34B5B8"
                    height="50"
                    width="50" />
                </div>}
              >
                <div className="mensagens-forum">
                  {
                    this.state.mensagens.map((mensagem) => <div key={mensagem.id}
                      className={`mensagem ${mensagem.idVinculo === this.props.dadosUsuario.idVinculo ? 'autor' : ''}`}>
                      <Row>
                        <Col md="6" className="text-left">
                          <p><b>{mensagem.autor} - {mensagem.papel}</b></p>
                        </Col>
                        <Col md="6" className="text-right">
                          <p className="data">{mensagem.data}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Quill
                            readOnly
                            noBorder
                            value={mensagem.mensagem} />
                        </Col>
                      </Row>
                      {
                        mensagem.idVinculo === this.props.dadosUsuario.idVinculo && <Row>
                          <Col className="text-right">
                            <Button
                              color="danger"
                              type="button"
                              onClick={() => { this.confirmarRemocaoMensagem(mensagem); }}>
                              Excluir
                            </Button>&nbsp;&nbsp;&nbsp;
                          </Col>
                        </Row>
                      }
                    </div>)
                  }
                </div>
              </InfiniteScroll> : <div align="center" style={{ margin: 50 }}>
                Nenhum mensagem ainda.
              </div>
            }
          </Col>
        </Row>
        <Row>
          <Col md="6" className="text-left">
            <Button color="primary"
              onClick={() => this.props.history.goBack()}>
              Voltar
            </Button>
          </Col>
        </Row>
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerMensagem();
            } else {
              this.setState({ mensagemSelecionada: null });
            }
          }}
          text='Confirme a exclusão da mensagem' />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
        <GetLongStringModal
          isOpen={this.state.showGetStringModal}
          headerText="Informe o texto da mensagem"
          confirmButtonText={!this.state.mensagemSelecionada ? 'Cadastrar' : 'Atualizar'}
          value={this.state.mensagemSelecionada ? this.state.mensagemSelecionada.mensagem : ''}
          callback={(mensagem) => {
            if (!mensagem) {
              this.setState({ showGetStringModal: false });
              return;
            }
            if (!this.state.mensagemSelecionada) {
              this.setState({ showGetStringModal: false });
              this.cadastrarMensagem(mensagem);
            } else {
              this.setState({ showGetStringModal: false });
              this.atualizarMensagem(mensagem);
            }
          }} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(Forum);
