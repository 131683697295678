/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  Input,
  FormGroup,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import moment from 'moment';
import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import AvaliacoesService from 'services/AvaliacoesService';
import UsuarioService from 'services/UsuarioService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';

import Turno from 'assets/csv/turnos.json';
import Papel from 'assets/csv/papeis.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';

class RelatorioAluno extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.salvarRelatorios = this.salvarRelatorios.bind(this);
    this.dataLimiteExcedida = this.dataLimiteExcedida.bind(this);

    this.state = {
      showAlert: false,
      showLoaderModal: false,
      buttonDisabled: false,

      error: null,
    };

    this.loginService = new LoginService();
    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.avaliacoesService = new AvaliacoesService();
    this.usuarioService = new UsuarioService();
    this.professorAuxiliarService = new ProfessorAuxiliarService();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const dadosEnte = await this.entesService
        .carregarDadosEnte();

      const professoreAuxiliar = await this.professorAuxiliarService
        .carregarMeuProfessorAuxiliar();

      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const avaliacoes = await this.avaliacoesService
        .carregarAvaliacoesAlfabetizacaoPorAluno(this.props.match.params.idDiario,
          this.props.match.params.idAluno);

      const aluno = await this.usuarioService
        .carregarUsuario(this.props.match.params.idAluno);

      const matriculas = await this.diariosService
        .carregarMatriculas(this.props.match.params.idDiario);

      const matricula = matriculas.find((m) => m.usuario.id === this.props.match.params.idAluno)

      const selectMatriculas = [];
      matriculas.forEach((matricula) => {
        selectMatriculas.push({
          label: matricula.usuario.nome,
          value: matricula.id,
          idUsuario: matricula.usuario.id,
        });
      });

      const matriculaSelecionada = {
        label: matricula.usuario.nome,
        value: matricula.id,
      };

      this.setState({
        dadosEnte,
        anoLetivo,
        escola,
        turma,
        diario,
        avaliacoes,
        aluno,
        matriculaSelecionada,
        selectMatriculas,
        auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
      });
    } catch (e) {
      console.log(e)
      this.setState({ erro: true });
    }
  }

  async salvarRelatorios() {
    this.setState({
      buttonDisabled: true,
      showAlert: false,
      showLoaderModal: true,
    });

    try {
      await this.avaliacoesService.salvarAvaliacoesAlfabetizacao(
        this.state.diario.id, this.state.avaliacoes,
      );

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Relatórios salvos com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao salvar relatórios',
      });
    }
  }

  async salvarEmBackground() {
    await this.avaliacoesService.salvarAvaliacoesAlfabetizacao(
      this.state.diario.id, this.state.avaliacoes,
    );
  }

  voltar() {
    if (this.state.turma.multiSeriado) {
      return `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}/diario`;
    }
    if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}`;
    }
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao`;
  }

  dataLimiteExcedida(avaliacao) {
    if (!this.state.dadosEnte.limitesBimestrais) return false;

    let dataLimite = null;
    if (avaliacao.descricaoAvaliacao.nome === '1º semestre' && this.state.anoLetivo.dataLimiteBimestre2) {
      dataLimite = moment(this.state.anoLetivo.dataLimiteBimestre2, 'DD/MM/YYYY');
    } else if (avaliacao.descricaoAvaliacao.nome === '2º semestre' && this.state.anoLetivo.dataLimiteBimestre4) {
      dataLimite = moment(this.state.anoLetivo.dataLimiteBimestre4, 'DD/MM/YYYY');
    }

    if (!dataLimite) return false;

    dataLimite.set('hours', 23);
    dataLimite.set('minutes', 59);
    dataLimite.set('seconds', 59);

    const today = moment();

    return today.isAfter(dataLimite)
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            }
            {
              !this.state.turma.multiSeriado
                ? <BreadcrumbItem><Link to={this.voltar()}>{this.state.diario.nome}</Link></BreadcrumbItem>
                : <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}/diario`}>Diário único</Link></BreadcrumbItem>
            }
            <BreadcrumbItem active>{this.state.aluno.nome}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                Relatório Parcial
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup className={'has-label has-danger'}>
                    <label>Aluno</label>
                    <Select
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={'react-select primary'}
                      classNamePrefix="react-select"
                      value={this.state.matriculaSelecionada}
                      onChange={async (event) => {
                        this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
                          + `/escola/${this.props.match.params.idEscola}/turma/${this.props.match.params.idTurma}`
                          + `/diario/${this.props.match.params.idDiario}/relatorio`
                          + `/aluno/${event.idUsuario}`);

                        this.setState({ loadingRelatorios: true });
                        await this.componentDidMount();
                        this.setState({ loadingRelatorios: false });
                      }}
                      options={this.state.selectMatriculas}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {
                this.state.loadingRelatorios ? <div align="center" style={{ padding: 30 }}>
                  <Loader
                    type="Oval"
                    color="#34B5B8"
                    height="50"
                    width="50" />
                </div> : <Row>
                  <Col className="mt-3" md="12">
                    <Tabs>
                      <TabList>
                        {
                          this.state.avaliacoes
                            .map((avaliacao, idx) => <Tab key={idx}>
                              {avaliacao.descricaoAvaliacao.nome} *
                            </Tab>)
                        }
                      </TabList>
                      {
                        this.state.avaliacoes
                          .map((avaliacao, idx) => <TabPanel key={idx}>
                            <Input
                              disabled={this.props.role !== Papel.PROFESSOR.value || this.dataLimiteExcedida(avaliacao)}
                              value={avaliacao.resultado}
                              onChange={(evt) => {
                                const diff = evt.target.value.length - avaliacao.resultado.length;
                                Object.assign(
                                  avaliacao, { resultado: evt.target.value },
                                );
                                if ((evt.target.value.length > 0
                                  && evt.target.value.length % 140 === 0) || diff > 140) {
                                  this.salvarEmBackground();
                                }
                                this.forceUpdate();
                              }}
                              type="textarea"
                              style={{ height: '500px' }} />
                          </TabPanel>)
                      }
                    </Tabs>
                    <div className="category form-category">
                      * Campos obrigatórios
                    </div>
                  </Col>
                </Row>
              }
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history
                      .push(this.voltar())} >
                    Voltar
                  </Button>
                </Col>
                <Col className="text-right">
                  {
                    this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado && <Button
                      color="primary"
                      disabled={this.state.buttonDisabled}
                      onClick={this.salvarRelatorios} >
                      Salvar relatórios
                    </Button>
                  }
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text="Salvando notas..." />
      </div>
    );
  }
}

export default RelatorioAluno;
