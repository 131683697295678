import React from 'react';
import { Link } from 'react-router-dom';

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Input,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import { connect } from 'react-redux';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmModal from 'views/components/ConfirmModal';
import AdaptiveTable from 'views/components/AdaptiveTable';
import Quill from 'views/components/Quill';

import LoginService from 'services/LoginService';
import TurmasService from 'services/TurmasService';
import MateriasService from 'services/MateriasService';
import TarefasService from 'services/TarefasService';

import Turno from 'assets/csv/turnos.json';

class TarefaAluno extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-envios-${this.props.match.params.idTarefa}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.fileInputRef = React.createRef();

    this.loginService = new LoginService();
    this.turmasService = new TurmasService();
    this.materiasService = new MateriasService();
    this.tarefasService = new TarefasService();

    this.state = {
      showAlert: false,
      erro: false,

      conteudo: '',

      dadosTabela: {
        header: [['Nome', '70%'], ['Data', '20%']],
        columnAlign: ['text-left', 'text-center'],
        keys: ['nome', 'data'],
        rows: [],
        total: undefined,
      },
    };

    this.fileChange = this.fileChange.bind(this);
  }

  componentWillUnmount() {
    this.turmasService.abortPedingRequests();
    this.materiasService.abortPedingRequests();
    this.tarefasService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const matricula = await this.turmasService
        .carregarMatriculaPorId(this.props.match.params.idMatricula);

      const turma = await this.turmasService
        .carregarTurma(matricula.idTurma);

      const materia = await this.materiasService
        .carregarMateria(this.props.match.params.idMateria);

      const tarefa = await this.tarefasService
        .carregarTarefa(this.props.match.params.idTarefa);

      const conteudo = await this.tarefasService
        .carregarTexto(this.props.match.params.idTarefa);

      this.carregarEnvios(this.paginaSelecionada, this.linhasPorPagina, this.like);

      this.setState({
        conteudo,
        turma,
        tarefa,
        materia,
      });

      this.setState({ ready: true });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarEnvios(pagina, linhasPorPagina, like) {
    try {
      const data = await this.tarefasService
        .carregarEnvios(this.props.match.params.idTarefa,
          pagina, linhasPorPagina, like);

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.envios, total: data.total }),
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar envios',
      });
    }
  }

  fileChange(e) {
    this.setState({ showAlert: false });
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(file.name)[1];

    if (!ext) {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao carregar arquivo selecionado. Não é possível enviar arquivos sem extensão.',
      });
      return;
    }

    if (file) {
      reader.onloadend = async () => {
        if (file.size > 10000000) {
          this.setState({
            showAlert: true,
            alertColor: 'danger',
            alertMsg: `Erro: Arquivo muito grande. O tamanho do arquivo é ${(file.size / 1000000).toFixed(2)}MB e o tamanho máximo permitido é 10MB`,
          });
        } else {
          const regexp = /^data:((\w+)\/(.+));base64,(.+)$/g;
          const matches = regexp.exec(reader.result);

          if (matches.length !== 5) {
            this.setState({
              showAlert: true,
              alertColor: 'danger',
              alertMsg: 'Erro ao carregar arquivo selecionado.',
            });
          } else {
            try {
              this.setState({
                buttonDisabled: true,
                loaderModalText: 'Fazendo upload...',
              });

              const arquivo = {
                nome: file.name,
                conteudo: matches[4],
                extensao: ext,
                tipo: matches[1],
              };

              await this.tarefasService
                .enviarArquivo(this.props.match.params.idTarefa, arquivo);

              this.setState({
                buttonDisabled: false,
                showAlert: true,
                alertColor: 'success',
                alertMsg: 'Arquivo enviado com sucesso',
              });

              this.carregarEnvios(this.paginaSelecionada, this.linhasPorPagina, this.like);
            } catch (msg) {
              this.setState({
                buttonDisabled: false,
                showAlert: true,
                alertColor: 'danger',
                alertMsg: msg || 'Erro ao enviar arquivo selecionado.',
              });
            }
          }
        }
        this.fileInputRef.current.value = '';
      };
      reader.readAsDataURL(file);
    }
    e.target.value = "";
  }

  confirmarRemocaoEnvio(envio) {
    this.setState({ envioSelecionado: envio, showConfirmModal: true });
  }

  async removerEnvio() {
    this.setState({ showAlert: false, buttonDisabled: true });

    try {
      await this.tarefasService
        .removerEnvio(this.state.envioSelecionado.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Envio removido com sucesso',
      });

      this.carregarEnvios(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover envio',
      });
    }
  }

  async salvarTexto() {
    this.setState({ showAlert: false, buttonDisabled: true });

    try {
      await this.tarefasService
        .salvarTexto(this.props.match.params.idTarefa,
          this.state.conteudo);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Texto salvo com sucesso',
      });
    } catch (msg) {
      console.log(msg);
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao salvar texto',
      });
    }
  }

  conteudoPagina() {
    return this.state.ready ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/turmas`}>Turmas</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/turmas/${this.props.match.params.idMatricula}`}>{this.state.turma.curso.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/turmas/${this.props.match.params.idMatricula}/turma/${this.props.match.params.idTurma}/materia/${this.props.match.params.idMateria}/turmavirtual`}>Turma virtual - {this.state.materia.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Tarefa</BreadcrumbItem>
          </Breadcrumb>
          <Card>
            <CardBody>
              <div className="fileinput text-center">
                <input
                  type="file"
                  onChange={this.fileChange}
                  accept="*/*"
                  ref={this.fileInputRef} />
              </div>
              <Tabs>
                <TabList>
                  <Tab>Informações da Tarefa</Tab>
                  <Tab>Texto</Tab>
                  <Tab>Anexos</Tab>
                </TabList>
                <TabPanel>
                  <Row>
                    <Col md="8">
                      <FormGroup>
                        <label>Nome *</label>
                        <Input
                          disabled
                          type="text"
                          value={this.state.tarefa.nome}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <label>Data de início *</label>
                        <Input
                          disabled
                          type="text"
                          value={this.state.tarefa.dataInicio}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <label>Data de fim *</label>
                        <Input
                          disabled
                          type="text"
                          value={this.state.tarefa.dataFim}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Descrição *</label>
                        <Input
                          disabled
                          value={this.state.tarefa.descricao}
                          type="textarea"
                          style={{ height: '400px' }} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="text-left">
                      <Button color="primary"
                        onClick={() => this.props.history.goBack()}>
                        Voltar
                      </Button>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel>
                  <Row>
                    <Col>
                      <Quill
                        hideImage
                        hideVideo
                        value={this.state.conteudo}
                        onChange={(e) => {
                          this.setState({ conteudo: e });
                        }}
                        callbackMessage={(msg) => this.setState(msg)}
                        callbackUpload={async (arquivo) => {
                          await this.tarefasService.enviarArquivo(this.props.match.params.idTarefa, arquivo);
                          await this.carregarEnvios(this.paginaSelecionada, this.linhasPorPagina, this.like);

                          this.setState({
                            buttonDisabled: false,
                            showAlert: true,
                            alertColor: 'success',
                            alertMsg: 'Arquivo enviado com sucesso',
                          });

                          return null;
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col md="6" className="text-left">
                      <Button color="primary"
                        onClick={() => this.props.history.goBack()}>
                        Voltar
                      </Button>
                    </Col>
                    <Col md="6" className="text-right">
                      <Button color="primary"
                        onClick={() => this.salvarTexto()}>
                        Salvar texto
                      </Button>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel>
                  <Row>
                    <Col>
                      <AdaptiveTable
                        selectedPage={this.paginaSelecionada}
                        rowsPerPage={this.linhasPorPagina}
                        disablePrintAction
                        disableEditAction
                        clickRows
                        removeButtonCallback={(envio) => this.confirmarRemocaoEnvio(envio)}
                        rowsPerPageCallback={(info) => {
                          this.paginaSelecionada = 1;
                          sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                          this.linhasPorPagina = info.rowsPerPage;
                          sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                          this.carregarEnvios(
                            this.paginaSelecionada, info.rowsPerPage, this.like,
                          );
                        }}
                        likeCallback={(text) => {
                          this.like = text;
                          this.paginaSelecionada = 1;
                          sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                          this.carregarEnvios(
                            this.paginaSelecionada, this.linhasPorPagina, text,
                          );
                        }}
                        paginatorCallback={(page) => {
                          this.paginaSelecionada = page;
                          sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                          this.carregarEnvios(
                            this.paginaSelecionada, this.linhasPorPagina, this.like,
                          );
                        }}
                        rowCallback={(envio) => window.open(envio.url)}
                        data={this.state.dadosTabela} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" className="text-left">
                      <Button color="primary"
                        onClick={() => this.props.history.goBack()}>
                        Voltar
                      </Button>
                    </Col>
                    <Col md="6" className="text-right">
                      <Button color="primary"
                        onClick={() => this.fileInputRef.current.click()}>
                        Anexar arquivo
                      </Button>
                    </Col>
                  </Row>
                </TabPanel>
              </Tabs>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#34B5B8"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerEnvio();
            } else {
              this.setState({ envioSelecionado: null });
            }
          }}
          text='Confirme a exclusão do envio' />
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text='Removendo envio...' />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(TarefaAluno);
