/* eslint-disable max-len */
import React from 'react';

import {
  Row,
  Col,
  Button,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import DiariosService from 'services/DiariosService';
import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';

import CicloEnsino from 'assets/csv/ciclos-ensino.json';
import Serie from 'assets/csv/series2.json';
import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';

class RelatorioAlunoTurmaMultiseriado extends React.Component {
  constructor(props) {
    super(props);

    this.situacoesMatricula = [
      SituacaoMatricula.MATRICULADO,
      SituacaoMatricula.TRANSFERIDO,
      SituacaoMatricula.DESISTENTE,
      SituacaoMatricula.APROVADO,
      SituacaoMatricula.APROVADO_COM_DEPENDENCIA,
      SituacaoMatricula.REPROVADO_POR_NOTA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA_E_NOTA,
      SituacaoMatricula.NAO_MATRICULADO,
      SituacaoMatricula.FALECIDO,
    ];

    this.state = {
      loading: true,
    };

    this.diariosService = new DiariosService();
    this.entesService = new EntesService();
    this.anosLetivosService = new AnosLetivosService();
  }

  async componentDidMount() {
    const dadosEnte = await this.entesService
      .carregarDadosEnte();

    const anoLetivo = await this.anosLetivosService
      .carregarAnoLetivo(this.props.idAnoLetivo);

    const diarios = await this.diariosService
      .carregarDiariosDaTurma(this.props.idTurma);

    const diario = diarios.find((d) => d.idTurma === this.props.idTurma);

    const materias = await this.diariosService
      .carregarMateriasDoProfessor(diario.id);

    const matriculas = await this.diariosService
      .carregarMatriculas(diario.id);

    this.setState({
      dadosEnte,
      matriculas,
      diario,
      loading: false,
      avaliacaoAtelie: dadosEnte.avaliacaoAtelie && parseInt(anoLetivo.ano) >= dadosEnte.avaliacaoAtelieAno && materias.some((materia) => materia.atelie),
    });
  }

  avaliarAluno(matricula) {
    this.props.history.push(`${this.props.layout}/anosletivos/${this.props.idAnoLetivo}`
      + `/escola/${this.props.idEscola}`
      + `/turma/${this.props.idTurma}`
      + `/diario/${this.state.diario.id}/relatorio`
      + `/aluno/${matricula.usuario.id}`);
  }

  avaliarAlunoAtelie(matricula) {
    const address = `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.props.idEscola}`
      + `/turma/multiseriado/${this.props.idTurma}`
      + `/diario/${this.state.diario.id}`;

    this.props.history.push(`${address}/atelie/${matricula.id}`);
  }

  lancarHabilidadesObjetivosAprendizagemAluno(matricula) {
    if (matricula.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value && !this.state.dadosEnte.bnccNacional) {
      this.lancarObjetivosAprendizagemAluno(matricula);
    } else {
      this.lancarHabilidadesAluno(matricula);
    }
  }

  lancarAvaliacaoEspecialAluno(matricula) {
    const address = `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.props.match.params.idEscola}`
      + `/turma/${this.props.idTurma}/diario/${this.state.diario.id}`;
    this.props.history.push(`${address}/avaliacao/especial/matricula/${matricula.id}`);
  }

  lancarHabilidadesAluno(matricula) {
    const address = `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.props.match.params.idEscola}`
      + `/turma/multiseriado/${this.props.match.params.idTurma}/diario`;
    this.props.history.push(`${address}/objetivos/habilidades/matricula/${matricula.id}`);
  }

  lancarObjetivosAprendizagemAluno(matricula) {
    const address = `${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.props.match.params.idEscola}`
      + `/turma/multiseriado/${this.props.match.params.idTurma}/diario`;
    this.props.history.push(`${address}/objetivos/habilidades/matricula/${matricula.id}`);
  }

  conteudoPagina() {
    return this.state.matriculas.length === 0 ? <Row>
      <Col className="text-center">
        <div style={{ margin: 50 }}>
          Nenhum aluno matriculado
        </div>
      </Col>
    </Row> : <Row>
      <Col>
        <Table hover>
          <thead className="text-primary">
            <tr>
              <th style={{ width: '40%' }}>Aluno</th>
              <th style={{ width: '40%' }}>Mãe</th>
              <th className="text-center"></th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.matriculas.map((matricula, idx) => (
                <tr key={idx}>
                  <td>{matricula.usuario.nome} {matricula.usuario.pne && <i className="fal fa-universal-access fa-lg" />} {matricula.situacao !== SituacaoMatricula.MATRICULADO.value ? `(${this.situacoesMatricula[matricula.situacao].label})` : ''}</td>
                  <td>{matricula.usuario.nomeMae}</td>
                  <td className="text-center">
                    <UncontrolledDropdown>
                      <DropdownToggle
                        aria-haspopup={true}
                        color="default"
                        data-toggle="collapse"
                        id={`acoesAlunoDropdownMenuLink${idx}`}
                        onClick={(evt) => evt.stopPropagation()}
                        nav>
                        <Button color="defaul">
                          Opções do aluno
                        </Button>
                      </DropdownToggle>
                      <DropdownMenu aria-labelledby="acoesAlunoDropdownMenuLink" right>
                        <DropdownItem
                          onClick={() => this.avaliarAluno(matricula)}>
                          Relatório descritivo
                        </DropdownItem>
                        {
                          (this.state.avaliacaoAtelie) && <DropdownItem
                            onClick={() => this.avaliarAlunoAtelie(matricula)}>
                            Avaliação ateliê
                          </DropdownItem>
                        }
                        {
                          this.state.dadosEnte.competenciasAluno && this.state.diario.serie < Serie.ENSINO_FUNDAMENTAL_4.value && <DropdownItem
                            onClick={() => this.lancarHabilidadesObjetivosAprendizagemAluno(matricula)}>
                            {matricula.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                              ? 'Objetivos de aprendizagem adquiridos' : 'Habilidades adquiridas'}
                          </DropdownItem>
                        }
                        {
                          this.state.dadosEnte.avaliacaoEspecial && matricula.usuario.pne && <DropdownItem
                            onClick={() => this.lancarAvaliacaoEspecialAluno(matricula)}>
                            PEI
                          </DropdownItem>
                        }
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </Col>
    </Row>;
  }

  render() {
    return this.state.loading ? <div align="center" style={{ padding: 30 }}>
      <Loader
        type="Oval"
        color="#34B5B8"
        height="50"
        width="50" />
    </div> : this.conteudoPagina();
  }
}

export default RelatorioAlunoTurmaMultiseriado;
